<template>
  <div class="position-relative w-100">
    <svg-search-icon class="search-icon" />

    <b-form-input
      v-model="searchedText"
      class="a-btn a-poppins-btn font-weight-normal search w-100"
      type="search"
      autocomplete="off"
      :placeholder="searchPlaceholderText"
      debounce="700"
      @keyup.enter="navToResultsPage"
      @blur="hideResults"
    />

    <div v-if="showSearchResults && searchResults" class="search-results">
      <Loadable
        :items="searchResults"
        :class="{
          'search-item':
            !searchResults || (searchResults && !searchResults.length),
        }"
      />
      <div v-if="searchResults">
        <div v-if="searchResults.length">
          <a
            v-for="(result, i) of searchResultsNavbar"
            :key="i"
            :href="cnpLink(result)"
            @click.prevent="onClick(result)"
            class="search-result"
          >
            <img :src="getCourseThumbnail(result)" />
            <span>
              <icon-series v-if="result._model === 'path'" class="icon mr-1" />
              {{ result.attributes.name }}
            </span>
          </a>
        </div>
        <div v-else-if="searchResults.length === 0">
          <div
            v-if="searchedText.length >= minSearchLength"
            class="search-item"
          >
            {{ $t("Nič sme nenašli") }}
          </div>
          <div v-else class="search-item">
            {{ $t("Zadajte aspoň") }} {{ minSearchLength }} {{ $t("znaky") }}`
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "/utils/axios";
import { mapGetters } from "vuex";
import { injectCourseMetaFields, getCourseThumbnailUrl } from "/utils/helpers";
import { get } from "lodash";

import * as Sentry from "@sentry/vue";

const api = {
  courses: (searchString) => {
    return axios.get("courses", {
      params: {
        populate: ["thumbnail", "subtitleLanguages.flag", "badges"],
        filters: {
          searchable: true,
          $or: [
            {
              name: {
                $containsi: searchString,
              },
            },
            {
              tagsString: {
                $containsi: searchString,
              },
            },
            {
              description: {
                $containsi: searchString,
              },
            },
          ],
        },
      },
    });
  },
  paths: (searchString) => {
    return axios.get("paths", {
      params: {
        populate: ["thumbnail", "subtitleLanguages.flag"],
        filters: {
          $or: [
            {
              name: {
                $containsi: searchString,
              },
            },
            {
              tagsString: {
                $containsi: searchString,
              },
            },
            {
              description: {
                $containsi: searchString,
              },
            },
          ],
        },
      },
    });
  },
};

export default {
  components: {
    "svg-search-icon": () => import("/assets/icons/search.svg?inline"),
    "icon-series": () => import("/assets/icons/iconSeries.svg?inline"),
    Loadable: () => import("/components/Loadable.vue"),
  },

  data() {
    return {
      courses: null,
      paths: null,
      /** Combination of Courses and Paths */
      cnp: null,
      searchConfig: { minLength: 3 },
      searchedText: "",
      searchQueuedUp: false,
      showSearchResults: false,
      searchResults: [],
    };
  },

  computed: {
    ...mapGetters("auth", ["isLoggedIn"]),
    minSearchLength() {
      return this.searchConfig.minLength || 0;
    },
    userType() {
      const user = this.$store.getters["auth/user"];
      return user && user.type;
    },
    isTeacher() {
      return this.userType === "teacher";
    },
    onTeachersPage() {
      return this.$route.path === "/pre-ucitelov";
    },
    searchPlaceholderText() {
      return this.onTeachersPage || this.isTeacher
        ? this.$t("Čo chcete naučiť svojich študentov?")
        : this.$t("V čom sa chceš pripraviť na život?");
    },
    searchResultsNavbar() {
      return this.searchResults.slice(0, 6);
    },
  },

  watch: {
    searchedText: async function (newValue) {
      this.searchResults = [];

      if (!newValue || newValue.length < this.minSearchLength) {
        return;
      }

      try {
        this.$eventLogger.log("course.search", { typed_text: newValue });

        const courses = await api.courses(newValue);
        this.courses = courses.data;

        this.courses.forEach((course) => {
          injectCourseMetaFields(course, "course");
        });

        const paths = await api.paths(newValue);
        this.paths = paths.data;

        this.paths.forEach((path) => {
          injectCourseMetaFields(path, "path");
        });

        this.searchResults = [...this.paths, ...this.courses];

        if (this.searchQueuedUp) {
          this.navToResultsPage();
        }

        this.showSearchResults = true;
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
  },

  methods: {
    onClick(cnpItem) {
      this.searchedText = "";
      this.showSearchResults = false;

      const cnpUrl = this.cnpLink(cnpItem);
      const allowRedirect =
        this.isLoggedIn ||
        get(cnpItem, "attributes.public") ||
        cnpItem._model === "path";
      if (!allowRedirect) {
        localStorage.setItem("LAST_FROM_ROUTE", cnpUrl);
        this.EventBus.$emit("locked-content-accessed");
        return;
      }

      this.$router.push(cnpUrl);
    },

    hideResults($event) {
      if (
        !$event.relatedTarget ||
        !$event.relatedTarget.classList.contains("search-result")
      )
        this.showSearchResults = false;
    },

    navToResultsPage($event) {
      if (!this.searchResults.length) {
        if ($event && !this.cnp) this.searchQueuedUp = true;
        else this.searchQueuedUp = false;
        return;
      }
      if (this.$route.name != "Kurzy" || !this.$route.query.search)
        this.$router.push({
          name: "Kurzy",
          params: {
            searchedCourses: this.searchResults,
            searchedText: this.searchedText,
          },
          query: {
            search: true,
          },
        });
      else
        this.$events.fire("courses-searched", {
          searchResults: this.searchResults,
          searchedText: this.searchedText,
        });
      this.$eventLogger.log("course.search", {
        searched_text: this.searchedText,
      });
    },

    /** Generate a link based on an underlying model: Course or Path */
    cnpLink(cnpItem) {
      if (cnpItem._model === "course") {
        return `/kurz/${cnpItem.attributes.slug || cnpItem.id}`;
      } else {
        return `/seria/${cnpItem.attributes.slug || cnpItem.id}`;
      }
    },

    getCourseThumbnail(course) {
      return getCourseThumbnailUrl(course);
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  height: auto;
  border: 0;
  background: var(--a-color-light-gray);
  color: rgba(var(--a-color-black-rgb), 0.4);
  padding-left: 2.75rem;

  &:focus,
  &:active {
    background: var(--a-color-white);
    color: var(--a-color-black);
    box-shadow: none;
    border: 1px solid var(--a-color-blue-light);
  }
}

.search-results {
  position: absolute;
  padding-left: 16px;
  padding-right: 20px;
  background: var(--a-color-white);
  border: 1px solid var(--a-color-blue-light);
  border-radius: 10px;
  box-shadow: 0px 4px 40px var(--a-color-blue-light);
  top: calc(100% + 10px);
  width: 100%;
  z-index: 1;
}

.search-item {
  display: flex;
  position: relative;
  align-items: center;
  padding: 10px;
  margin: 12px 0;
  font-size: 14px;
}

.search-result {
  font-weight: 700;
  min-height: 80px;
  @extend .search-item;

  &:first-of-type {
    margin-top: 6px;
  }

  &:last-of-type {
    margin-bottom: 6px;
  }

  &:not(:last-of-type) {
    &::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      bottom: -6px;
      left: 0px;
      background: var(--a-color-blue-light);
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    background-color: var(--a-color-blue-lightest);
    color: var(--a-color-black);
  }

  img {
    height: 48px;
    margin-right: 20px;
  }

  .icon::v-deep path {
    fill: #eb1b69;
  }
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  //   color: red;
  opacity: 0.6; /* Firefox */
}
.search-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1rem;
  color: rgba(var(--a-color-black-rgb), 0.4);
}
</style>
